import { Button } from "@nextui-org/react";
import Link from "next/link";
import { ReactGoogleReviews } from "react-google-reviews";

const Review = () => {
  return (
    <div className="container flex flex-col items-center mx-auto py-8 mt-6 shadow-card">
      <div className="mb-6">
        <ReactGoogleReviews
          layout="badge"
          featurableId="1f55ddbd-34cb-40dc-bb78-bf531fa6590e"
        />
      </div>
      <ReactGoogleReviews
        layout="carousel"
        featurableId="1f55ddbd-34cb-40dc-bb78-bf531fa6590e"
      />
      <Link
        href="https://g.page/r/CRRQ-rKrZXVlEAE/review"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="bg-blue-600 font-semibold text-white text-lg rounded-md px-8 py-6">
          Write a review
        </Button>
      </Link>
    </div>
  );
};

export default Review;
